import TAGGED from "../modules/tagged_content"

const [ w, d, content ] = [ window, document, 'menu' ]

// 詳細ページにて実行
const detail_init = async post_id => {
  let articles = await $.getArticles({ content, post_status: 'all' })
  let categories = await $.getCategories({ content })
  let the_article = articles.filter( a => (a.id == post_id) ).shift()
  the_article.category = categories.filter( c => c.id == the_article.category )[0]

  // タグボックス
  let tags = keywords => {
    let html = ""
    if(!keywords || keywords.length == 0) return ''
    keywords.forEach( key => html += `<a href="/${content}/?mode=list&tag=${ encodeURI( key ) }" class="tag">#${key}</a>` )
    return `<div class="${content}-detail__tags">${html}</div>`
  }

  // 詳細置き換え
  $('.element_detail').each( ( i, elm_detail ) => {
    let $head = $(`
      <div class="${content}-detail-head">
        ${the_article.category ? `<span class="cat">${the_article.category.name}</span>`: '' }
        <h3 class="title">${the_article.title}</h3>
        ${tags( the_article.keywords )}
      </div>
    `)
    $('.element_detail > .title, .detail_title').remove()

    $head.prependTo( elm_detail )

    // 共通ブロック読み込み
    $.get('/asset/html/menu-detail-foot.html').then( html => $('.entry', elm_detail ).after( html ) )

  })


    // ページングテキスト
    $.pagingText({
      prev:`
        <svg class="icon" height="27" width="27">
          <use href="/asset/svg/sprite.symbol.svg#circle_arrow-cir"></use><use class="arrow" href="/asset/svg/sprite.symbol.svg#arrow-left"></use>
        </svg>
        <span>前のページへ</span>
      `,
      next:`
        <svg class="icon" height="27" width="27">
          <use href="/asset/svg/sprite.symbol.svg#circle_arrow-cir"></use><use class="arrow" href="/asset/svg/sprite.symbol.svg#arrow-right"></use>
        </svg>
        <span>次のページへ</span>
      `,
      list:'お料理<br class="visible-xsmall">一覧'
    })

}


export default function MENU () {

  // 一覧ページのテンプレート(Vue template)
  const itemTemplate = `
    <div id="main" class="column-3-4">
      <transition-group class="${content}-list" tag="div" name="fade" mode="out-in" :class="[ 'is-paged-' + paged ]">
        <a class="${content}-item" :href="article.href" v-for="( article, ind ) in pagedArticles" :key="article.id" :class="[ 'is-item-' + ind ]">
          <figure class="${content}-item__img">
            <div class="${content}-item__img-cover" :style="'background-image:url(' + article.thumb + ')'">
              <img :src="article.thumb" width="200">
            </div>
            <span class="cate" :class="'is-cat'+article.category.id" v-if="article.category">{{ article.category.name }}</span>
          </figure>
          <div class="${content}-item__body">
            <div class="${content}-item__title">
              <span class="subject">
                {{ article.title | truncate }}
                <span class="new" v-if="article.is_new">new</span>
              </span>
              <span class="desc">{{ article.desc | truncate( 40 ) }}</span>
            </div>
            <div class="tags" v-if="article.keywords != null">
              <span class="tag" v-for="tag in article.keywords">#{{tag}}</span>
            </div>
          </div>
        </a>
      </transition-group>
      <pagination
        v-if="totalPage > 1"
        :paged="paged"
        :total-page="totalPage"
        :total-item="totalItem"
        :item-count="pagedArticles.length"
        :prevText="'前へ'"
        :nextText="'次へ'"
        @change="pageChange"
      />
    </div>
  `

  // カテゴリ一覧ページにて、記事ごとの要素置き換え
  const replaceItemTemplate = item => `
    <a class="${content}-item" href="${item.href}">
      <figure class="${content}-item__img">
        <div class="${content}-item__img-cover" style="background-image:url( ${item.thumb} )">
          <img src="${item.thumb}" width="200">
        </div>
      </figure>
      <div class="${content}-item__body">
        <div class="${content}-item__title">
          <span class="subject">
            ${item.title.str_truncate( 20 )}
            ${item.is_new ? '<span class="new">NEW</span>': ''}
          </span>
          <span class="desc">
            ${item.desc.str_truncate( 40 )}
          </span>
        </div>
      </div>
    </a>
  `

  // 関連記事のテンプレート(Vue template)
  const relatedItemTemplate = `
    <div class="${content}-related" v-if="relatedArticles.length >= 1">
      <h3 class="${content}-related__title">関連記事</h3>
      <div data-plugin="related_articles" class="${content}-list">
        <a class="${content}-item" :href="article.href" v-for="article in relatedArticles" :key="article.id">
          <figure class="${content}-item__img">
            <div class="${content}-item__img-cover" :style="'background-image:url(' + article.thumb + ')'"><img :src="article.thumb" width="200"></div>
            <span class="cate" :class="'is-cat'+article.category.id" v-if="article.category">{{ article.category.name }}</span>
          </figure>
          <div class="${content}-item__body">
            <div class="${content}-item__title">
              <h5 class="subject">{{ article.title | truncate }}</h5>
              <span class="desc">{{ article.desc | truncate( 40 ) }}</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  `

  TAGGED({
    perpage: 9,
    related: true,
    taglist_disp_list: true, // head / foot / both
    taglist_disp_detail: 'foot', // head / foot / both
    taglist_minimumTagAmount : 12, // タグの最低表示数
    taglist_hideUselessTags : 4, // 最低使用回数
    itemTemplate,
    replaceItemTemplate,
    relatedItemTemplate,
    callback(){
      // フリーブロックを追加する
      $.getFreeBlock({xml:'b_content_content1.xml'}).then( blocks => {
        let $container = $('#container','.is--list')
        if( blocks.mainhead && blocks.mainhead.length ) $container.prepend( $(blocks.mainhead) )
        if( blocks.mainfoot && blocks.mainfoot.length ) $container.append( $(blocks.mainfoot) )
      })
    }
  })
  $(() => {
    if( w.query_string('article') ) detail_init( w.query_string('article') )
  })
}