import 'slick-carousel'
import GET_ARTICLES from '../plugins/getArticles'
// import GOOGLEMAP from '../plugins/googlemap'
// import GET_ARTICLES from '../plugins/getArticles'

const [ w, d ] = [ window, document ]

const slider = {
  obj: '[data-plugin="slider"]',
  opt: {
    autoplay:true,
    fade: true,
    dots: true,
    speed: 1200,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    pauseOnFocus: false,
    arrows: false
  },
  init( opt ) {
    $(this.obj).slick( Object.assign( this.opt, opt ) )
  }
}

const init = () => {  
  $.when(w.load['mainvisual']).then( (html, slot ) => {

    // slider実行
    slider.init()

    // slot適用
    let $obj = $('[data-slot="mainvisual"]')
    slot ? $obj.replaceWith( slot ) : $obj.hide()

    // ティッカー
    let makeItem = item => $(`
      <a href="${item.href}" class="link">
        <span class="date">${item.date.replace(/\./g,'/')}</span> |
        <span class="cat">${item.category.name}</span>
        <span class="subject">${item.title}</span>
      </a>
    `)[0]
    GET_ARTICLES( '.mainvisual__newsticker-item[data-plugin="getArticles"]', { makeItem })
  })

  // menu
  $.when(w.load['block-menu']).then( () => {
    let makeItem = ( item, opt ) => {
        return $(`
        <a href="${item.href}" class="block-${opt.content}-item">
          <figure class="block-${opt.content}-item__img">
            <div class="block-${opt.content}-item__img-cover" style="background-image:url(${item.thumb})"><img src="${item.thumb}" alt="${item.title}" width="100%" height="100%" loading="lazy"></div>
          </figure>
          <div class="block-${opt.content}-item__body">
            <div class="block-${opt.content}-item__body-inner">
              <h4 class="subject">${item.title.str_truncate( 20 )}</h4>
              <span class="desc">${item.desc.str_truncate( 40 )}</span>
            </div>
          </div>
        </a>
      `)[0]
    }
    GET_ARTICLES('.block-menu-articles__list[data-plugin="getArticles"]', { makeItem })
  })

  // news
  $.when(w.load['block-news']).then( () => {
    let makeItem =  ( item, opt ) => $(`
      <a href="${item.href}" class="block-${opt.content}-item">
        <figure class="block-${opt.content}-item__img">
          <div class="block-${opt.content}-item__img-cover" style="background-image:url(${item.thumb})"><img src="${item.thumb}" alt="${item.title}" width="240" loading="lazy"></div>
        </figure>
        <div class="block-${opt.content}-item__body">
          <span class="date">${item.date.replace(/\./g,'/')}</span> | <span class="cate">${item.category.name}</span>
          <h5 class="subject">${item.title.str_truncate(24)}</h5>
          <p class="desc">${item.desc.str_truncate(60)}</p>
        </div>
      </a>
    `)[0]
    GET_ARTICLES('.block-news__articles[data-plugin="getArticles"]', { makeItem })
  })

  // sns
  $.when( w.load['block-sns'] ).then( () => {
    let fbroot = d.createElement('div')
    fbroot.id = 'fb-root'
    d.body.appendChild( fbroot )
    w.addScript({
      async: '',
      defer: '',
      crossorigin: "anonymous",
      src: "https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v9.0&appId=759600244139396&autoLogAppEvents=1",
      nonce: "dYMfcg69"
    })
  })

}

export default function HOME() {
  $( init )
}