import * as TAGSORT from '../plugins/tagsort'

const [ w, d, l ] = [ window, document, location ]
const content = (l.pathname)? l.pathname.replace(/^\/([^/]+)\/$/,'$1') : ''

const replace_item = config => {
  $('.contents .wrapLink','.element_list').each( (i,obj) => {
    let href = obj.getAttribute('href')
    let thumb = obj.querySelector('.thum_img > img').getAttribute('src')
    let is_new = obj.querySelectorAll('span.new').length > 0 ? true: false
    let catObj = obj.querySelector('.catetitle a')
    let cat = catObj? catObj.innerText : ''
    let dateObj = obj.querySelector('p.date')
    let date = dateObj? dateObj.innerText : false
    let title = obj.querySelector('h5 > a').getAttribute('title')
    let desc = obj.querySelector('p.catch').innerText
    $(obj).replaceWith( config.replaceItemTemplate({href,thumb,is_new,catObj,cat,dateObj,date,title,desc}) || `
    <a class="${content}-item" href="${href}">
      <figure class="${content}-item__img">
        <div class="${content}-item__img-cover" style="background-image:url( ${thumb} )">
          <img src="${thumb}" width="200">
        </div>
        ${(catObj)? `<span class="cate">${cat}</span>`: '' }
      </figure>
      <div class="${content}-item__body">
        <span class="subject">
          ${title}
          ${is_new ? '<span class="new">NEW</span>': ''}
        </span>
        ${(date)? `<small class="date">${date}</small>`: ''}
      </div>
    </a>
    `)
  })
}

const taglist_init = config => {

  const content = l.pathname.replace(/^\/([^/]+)\//,'$1')
  const template = config.itemTemplate || `
    <div id="main" class="column-3-4">
      <transition-group class="${content}-list" tag="div" name="fade" mode="out-in" :class="[ 'is-paged-' + paged ]">
        <a class="${content}-item" :href="article.href" v-for="( article, ind ) in pagedArticles" :key="article.id" :class="[ 'is-item-' + ind ]">
          <figure class="${content}-item__img">
            <div class="${content}-item__img-cover" :style="'background-image:url(' + article.thumb + ')'">
              <img :src="article.thumb" width="200">
            </div>
            <span class="cate" :class="'is-cat'+article.category.id" v-if="article.category">{{ article.category.name }}</span>
          </figure>
          <div class="${content}-item__body">
            <span class="cate" :class="'is-cat'+article.category.id" v-if="article.category">{{ article.category.name }}</span>
            <span class="subject">
              {{ article.title }}
              <span class="new" v-if="article.is_new">NEW</span>
            </span>
            <div class="tags" v-if="article.keywords != null">
              <span class="tag" v-for="tag in article.keywords">#{{tag}}</span>
            </div>
            <span class="desc">{{ article.desc }}</span>
            <small class="date">{{ datetostr(article.date * 1000 ,"YYYY/MM/DD")}}</small>
          </div>
        </a>
      </transition-group>
      <pagination
        v-if="totalPage > 1"
        :paged="paged"
        :total-page="totalPage"
        :total-item="totalItem"
        :item-count="pagedArticles.length"
        :prevText="'Prev'"
        :nextText="'Next'"
        @change="pageChange"
      />
    </div>
  `
  
  TAGSORT.set_config(config)

  // カテゴリ一覧以外、デフォルトの一覧表示を差し替える
  if( !w.query_string('cat') )
    $('.element_list', '#main').html('<p class="text-center padding-large">Loading...</p>')

  if( w.query_string('cat') ) {
    $('.element_list .list_title1').remove()
    replace_item( config )
  }

  $.when(
    TAGSORT.default({ template }),
    $.getJSON('/asset/api/getCategories/',{ content })
  ).then( ( res, categories ) => {
    // taglistの作成
    const $container = $(`<div class="${content}-taglist align-center"/>`)
    const catClass = 'class="button-cat"'
    const tagClass = 'class="button-tag"'

    const $catContainer = $(`<div class="${content}-taglist__categories" />`)
    const $tagContainer = $(`<div class="${content}-taglist__tags" />`)
    
    // ALL追加
    $catContainer.prepend(`<a href="/${content}/?mode=list" ${catClass}>全てを表示</a>`)

    // カテゴリリンク追加
    $.each(categories[0], ( i, cat ) => {
      const $link = $(`<a href="${cat.href}" ${catClass}>${cat.name}</a>`)
      $catContainer.append( $link )
    })

    // タグリンク追加
    $.each(res.tags, ( i, tag ) => {
      const is_active = ( w.query_string('tag') == tag.slug )
      const $taglink = $(`<a href="/${content}/?mode=list&tag=${tag.slug}" ${tagClass}>${tag.name}</a>`)

      $taglink.addClass( is_active ? 'is-current':'' )
      
      // 最小表示数を超える場合 かつ 使用頻度が低いものを非表示
      if( i >= config.taglist_minimumTagAmount && config.taglist_hideUselessTags > tag.count ){
        $taglink.addClass( 'is-short' ).hide()
      }

      $tagContainer.append( $taglink )
    })

    // 低使用頻度のタグ表示トグル
    if( $('.is-short', $tagContainer ).length > 0 ){

      const icon = {
        up : '<i class="fa fa-angle-double-up" aria-hidden="true"></i>',
        down : '<i class="fa fa-angle-double-down" aria-hidden="true"></i>',
        plus : '<i class="" aria-hidden="true">+</i>',
        minus : '<i class="" aria-hidden="true">-</i>'
      }
      const $tagDispToggleBtn = $(`<button class="button button-text button-xsmall">全てを表示 ${icon.plus}</button>`)

      $tagDispToggleBtn.on('click', function() {
        const $btn = $(this)
        const $container = $btn.parents(`.${content}-taglist__tags`)

        $('.is-short', $container).fadeToggle(200)
        $btn.toggleClass('is-active')

        const btnhtml = $btn.hasClass('is-active') ? `一部のみ表示 ${icon.minus}`: `全てを表示 ${icon.plus}`
        $btn.html( btnhtml )
      })

      $tagDispToggleBtn.appendTo( $tagContainer ).wrapAll(`<div class="${content}-taglist__btnWrap button-wrap" />`)
    }

    $catContainer.appendTo( $container )
    $tagContainer.appendTo( $container )

    if( (/article=\d+/).test( l.search ) ){
      const articleID = l.search ? l.search.match(/article=(\d+)/)[1] : 0
      const articleData = res.articles.filter( v => v.id == articleID )
      if( articleData.length > 0 && articleData[0].category != undefined ){
        $catContainer.currentMenu({ mode: 0, url: articleData[0].category.href })
      }
      // 関連記事
      if( config.related != false ){
        $('#container').after( '<div data-plugin="related_articles" />' )
        TAGSORT.set_config ({ related_perpage: 4 })
        TAGSORT.related_articles({
          template: config.relatedItemTemplate || `
            <div class="${content}-related">
              <h3 class="${content}-related__title" v-if="relatedArticles.length >= 1">関連記事</h3>
              <div data-plugin="related_articles" class="${content}-list" v-if="relatedArticles.length >= 1">
                <a class="${content}-item" :href="article.href" v-for="article in relatedArticles" :key="article.id">
                  <figure class="${content}-item__img" :style="'background-image:url(' + article.thumb +')'">
                    <div class="${content}-item__img-cover" :style="'background-image:url(' + article.thumb + ')'"><img :src="article.thumb" width="200"></div>
                  </figure>
                  <div class="${content}-item__body">
                    <span class="cate" :class="'is-cat'+article.category.id" v-if="article.category">{{ article.category.name }}</span>
                    <h5 class="subject">{{ article.title }}</h5>
                    <div class="tags" v-if="article.keywords != null">
                      <span class="tag" v-for="tag in article.keywords">{{tag}}</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          `
        })
      }
    }else{
      $container.currentMenu({ mode: 0, url: l.pathname + l.search.replace(/&?&page=\d+/,'')})
    }

    // タグリストを上部と下部に追加
    switch( config.taglist_disp_list ){
      case 'head':
        $('#main', '.is--list' ).before( $container.clone(true) )
        break
      case 'foot':
        $('#main', '.is--list' ).after( $container.clone(true) )
        break
      case true:
      case 'both':
        $('#main', '.is--list' ).before( $container.clone(true) )
        $('#main', '.is--list' ).after( $container.clone(true) )
    }
    switch( config.taglist_disp_detail ){
      case 'head':
        $('.element_detail', '.is--detail').before( $container.clone(true).addClass('is-head') )
        break
      case 'foot':
        $('.containerWrap', '.is--detail').append( $container.clone(true).addClass('is-foot') )
        break
      case true:
      case 'both':
        $('.element_detail', '.is--detail').before( $container.clone(true).addClass('is-head') )
        $('.containerWrap', '.is--detail').append( $container.clone(true) .addClass('is-foot'))
    }

    config.callback()
  })
}

export default function TAGGED ( config ) {
  const conf = Object.assign({ 
    perpage: 12,
    related: true,
    taglist_disp_list: true, // head / foot / both
    taglist_disp_detail: 'foot', // head / foot / both
    taglist_minimumTagAmount : 12, // タグの最低表示数
    taglist_hideUselessTags : 4, // 最低使用回数
    itemTemplate: false,
    replaceItemTemplate: false,
    relatedItemTemplate: false,
    callback(){}
  }, config)

  $(() => taglist_init( conf ) )
}