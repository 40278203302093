/***
 * タグソートプラグイン
 *
 * メソッド一覧
 * * TAGSORT ( opt, pagination_opt )
 * * set_config ( config )
 * * get_the_tags ( post_id )
 * * related_articles ( option )
 */
import Vue from 'vue/dist/vue.esm'

const [ w, d, l ] = [ window, window.document, location]

function createEvent_anybrowser (e_name) {
  let e
  try {
    e = new CustomEvent( e_name );
  } catch (e) {
    e = document.createEvent('CustomEvent');
    e.initCustomEvent( e_name , false, false, '' );
  }
  return e
}

let config = {
  content: l.pathname.replace(/^\/(.*)\//,'$1') || undefined,
  perpage: 20,
  related_perpage: 8,
  event: {
    'pageChange': createEvent_anybrowser('tagsort.pageChange')
  }
}

export function set_config ( conf ) {
  config = Object.assign(config, conf)
}

export async function get_the_tags ( post_id ) {
  const getArticle = await $.getJSON('/asset/api/getArticles/',{
    content: config.content,
    post_id
  })
  return getArticle[0].keywords
}

const getParam = param => {
  let paramObj = {}
  let paramArr = l.search.substr(1).split('&').map( v => {
    const p = v.split('=')
    paramObj[ p[0] ] = p[1]
  })
  return param? paramObj[param] : paramObj
}

const getArticles = async () => {
  let articles = await $.getJSON('/asset/api/getArticles/',{
    content: config.content,
    post_per_page: -1,
    post_status: 'publish',
  })

  return articles
}

const getCategories = async () => {
  let categories = await $.getJSON('/asset/api/getCategories/',{
    content: config.content
  })
  return categories
}

export function getTags ( articles ) {
  const tags = []
  articles.map( item => {
    if(item.keywords != null && item.keywords.length >= 1 ){
      item.keywords.map( tag => {
        const slug = encodeURI(tag)
        const targetTag = tags.find( tag => tag.slug === slug )
        if ( targetTag != undefined ) targetTag.count++
        else tags.push({ slug, name: tag, count: 1 })
      })
    }
  })
  tags.sort( (a,b) => b.count - a.count )
  return tags
}

const render_list = ( articles, opt, pagination_opt ) => {
  const option = Object.assign({
    el: '#main, [role="main"]',
    data: {
      articles,
      paged: 1,
      totalPage: 0,
      totalItem: articles.length,
    },
    template: `
      <div id="main" class="column-3-4">
        <transition-group class="p-list" tag="div" name="fade" mode="out-in">
          <a class="p-item" :href="article.href" v-for="article in pagedArticles" :key="article.id">
            <figure class="p-item__img">
              <span class="p-item__cat" :class="'is-cat'+article.category.id" v-if="article.category">{{ article.category.name }}</span>
              <img :src="article.thumb" width="200">
            </figure>
            <div class="p-item__body">
              <h5 class="p-item__title">{{ article.title }}</h5>
              <div class="p-item__tags" v-if="article.keywords != null">
                <span v-for="keyword in article.keywords">{{keyword}}</span>
              </div>
            </div>
          </a>
        </transition-group>
        <pagination
          v-if="totalPage > 1"
          :paged="paged"
          :total-page="totalPage"
          :total-item="totalItem"
          :item-count="pagedArticles.length"
          :prevText="'PREV'"
          :nextText="'NEXT'"
          @change="pageChange"
        />
      </div>
    `,
    computed: {
      pagedArticles () {
        return this.filterArticles().slice( (this.paged - 1) * config.perpage, this.paged * config.perpage)
      }
    },
    methods: {
      filterArticles () {
        return this.articles.filter( v => {
          let res = true
          const params = getParam()
          Object.keys( params ).forEach( param => {
            switch( param ){
              case 'tag':
                res = ( v.keywords && v.keywords.indexOf( decodeURI( params.tag ) ) != -1 )
                break
            }
          })
          return res
        })
      },
      pageChange (page) {
        this.paged = page
        $('html,body').animate({scrollTop: 0 }, 200 )
        let suffix = getParam('tag')? '?mode=list&tag='+getParam('tag')+'&page=': '?page='
        w.history.pushState(
          { page },
          `Page${page}`,
          `${l.pathname}${suffix}${page}`
        )
        d.dispatchEvent( config.event.pageChange )
      },
      datetostr (unixtime,format) {
        const date = new Date( unixtime )
        const month = ["january","february","march","april","may","june","july","august","september","october","november","december"]
        const weekday = ["日", "月", "火", "水", "木", "金", "土"]
        const weekday_en = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
        format = format
          .replace(/YYYY/g, date.getFullYear())
          .replace(/MMMM/g, month[date.getMonth()])
          .replace(/MMM/g, month[date.getMonth()].slice(3))
          .replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
          .replace(/DD/g, ('0' + date.getDate()).slice(-2))
          .replace(/D/g, date.getDate())
          .replace(/dddd/g, weekday_en[date.getDay()])
          .replace(/ddd/g, weekday_en[date.getDay()].slice(3))
          .replace(/dd/g, weekday[date.getDay()])
          .replace(/hh/g, ('0' + date.getHours()).slice(-2))
          .replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
          .replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
        return format
      }
    },
    mounted () {
      this.totalPage = Math.ceil( this.filterArticles().length / config.perpage )
      this.totalItem = this.filterArticles().length
      if( getParam('page') ) this.pageChange( parseInt( getParam('page') ) )
      w.addEventListener('popstate', () => {
        if( getParam('page') ) this.pageChange( parseInt( getParam('page') ) )
      })
    },
    filters: {
      truncate(value, length, omission) {
        var length = length ? parseInt(length, 10) : 20;
        var ommision = omission ? omission.toString() : '...';
      
        if (value.length <= length) {
          return value;
        }
      
        return value.substring(0, length) + ommision;
      }
    }
  }, opt )

  const pagination_option = Object.assign({
    data () {
      return {
        currentPage: this.paged,
        perpage: config.perpage
      }
    },
    props:{
      paged: Number,
      totalPage:{
        type: Number,
      },
      totalItem:{
        type: Number,
      },
      itemCount:{
        type: Number,
      },
      prevText: {
        type: String,
        default: 'PREV'
      },
      nextText: {
        type: String,
        default: 'NEXT'
      },
    },
    methods:{
      prev () {
        this.currentPage = this.prevPage
        this.$emit('change', this.currentPage )
      },
      next () {
        this.currentPage = this.nextPage
        this.$emit('change', this.currentPage )
      },
      changePage (page) {
        this.currentPage = page
        this.$emit('change', page )
      }
    },
    computed:{
      is_first () {
        return this.paged == 1
      },
      is_last () {
        return this.totalPage == this.paged
      },
      suffix () {
        return getParam('tag')? '?mode=list&tag='+getParam('tag')+'&page=': '?page='
      },
      prevPage() {
        return Math.max(this.currentPage - 1, 1);
      },
      nextPage() {
        return Math.min(this.currentPage + 1, this.totalPage);
      },
      pagelist() {
        const disp_page_amt = 5
        const current_arr_amt = 3
        let pageArr = []
        if( this.totalPage <= disp_page_amt ) return this.totalPage
        let currentArr = new Array(current_arr_amt).fill(null).map((v,i) => {
          if( this.currentPage == 1 ) return this.currentPage + i
          if( this.currentPage == this.totalPage ) return this.currentPage - 2 + i
          return this.currentPage - 1 + i
        })
        if( currentArr[0] >= current_arr_amt ) currentArr.unshift('...') 
        if( currentArr[ currentArr.length - 1 ] <= this.totalPage - current_arr_amt ) currentArr.push('...') 
        let allPageArr = new Array(this.totalPage).fill(null).map((v,i) => i + 1)
        let edge_amt = Math.round( ( disp_page_amt - current_arr_amt ) / 2 )
        let edge_firstArr = allPageArr.slice( 0, edge_amt )
        let edge_endArr = allPageArr.slice( allPageArr.length - edge_amt )
        pageArr.push( ...edge_firstArr, ...currentArr, ...edge_endArr )
        pageArr = pageArr.filter( ( v, i, self ) => v == '...' || self.indexOf(v) === i )
        return pageArr
      }
    },
    template: `
      <div class="paginationbox">
        <p class="totalpages">全部で<strong>{{ totalItem }}</strong>件中 <strong>{{ ( (paged - 1) * perpage ) + 1 }}-{{ ( (paged - 1) * perpage ) + itemCount }}</strong>件を表示</p>
        <!-- /.totalpages -->
        <div class="pageNav">
          <a :href="suffix + prevPage" class="prev" :class="[is_first ? 'disable': '']" @click.prevent="prev">{{ prevText }}</a>
          <ul class="pages">
            <li v-for="page in pagelist">
              <i v-if="page == '...'">{{page}}</i>
              <a v-if="page != paged && page != '...'" :href="suffix + page" @click.prevent="changePage(page)">{{page}}</a>
              <span v-if="page == paged">{{page}}</span>
            </li>
          </ul>
          <!-- /.pages -->
          <a :href="suffix + nextPage" class="next" :class="[is_last ? 'disable': '']" @click.prevent="next">{{ nextText }}</a>
        </div>
        <!-- /.pageNav -->
      </div>
    `,
    mounted () {
      if( getParam('page') ) this.currentPage = parseInt( getParam('page') )
      w.addEventListener('popstate', () => {
        if( getParam('page') ) this.currentPage = parseInt( getParam('page') )
      })
    }
  }, pagination_opt )

  // ページング用コンポーネント追加
  Vue.component('pagination', pagination_option )

  new Vue(option)
}

export async function related_articles ( option ) {
  const post_id = getParam('article')
  const articles = await getArticles()
  const categories = await getCategories()
  const this_tags = await get_the_tags( post_id )
  articles.map( article => {
    article.category = categories.filter( c => c.id == article.category )[0]
  })
  const opt = Object.assign({
    el: '[data-plugin="related_articles"]',
    data: {
      articles
    },
    template: `
      <div data-plugin="related_articles" class="p-related-list" v-if="relatedArticles.length >= 1">
        <a class="p-related-item" :href="article.href" v-for="article in relatedArticles" :key="article.id">
          <figure class="p-related-item__img"><img :src="article.thumb" width="200"></figure>
          <div class="p-related-item__body">
            <h5 class="p-related-item__title">{{ article.title }}</h5>
            <div class="p-related-item__tags" v-if="article.keywords != null">
              <span v-for="keyword in article.keywords">{{keyword}}</span>
            </div>
          </div>
        </a>
      </div>
    `,
    computed: {
      relatedArticles (){
        return articles.filter( item => {
          if( item.id == post_id ) return false
          let related_tags = [...new Set( item.keywords )]
            .filter( tag => {
                if( this_tags != null && this_tags.length ) return this_tags.includes( tag )
              })
              return related_tags && related_tags.length >= 1
            })
            .slice( 0, config.related_perpage )
      }
    },
    methods: {
      datetostr (unixtime,format) {
        const date = new Date( unixtime )
        const month = ["january","february","march","april","may","june","july","august","september","october","november","december"]
        const weekday = ["日", "月", "火", "水", "木", "金", "土"]
        const weekday_en = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
        format = format
          .replace(/YYYY/g, date.getFullYear())
          .replace(/MMMM/g, month[date.getMonth()])
          .replace(/MMM/g, month[date.getMonth()].slice(3))
          .replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
          .replace(/DD/g, ('0' + date.getDate()).slice(-2))
          .replace(/D/g, date.getDate())
          .replace(/dddd/g, weekday_en[date.getDay()])
          .replace(/ddd/g, weekday_en[date.getDay()].slice(3))
          .replace(/dd/g, weekday[date.getDay()])
          .replace(/hh/g, ('0' + date.getHours()).slice(-2))
          .replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
          .replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
        return format
      }
    },
    filters: {
      truncate(value, length, omission) {
        var length = length ? parseInt(length, 10) : 20;
        var ommision = omission ? omission.toString() : '...';
      
        if (value.length <= length) {
          return value;
        }
      
        return value.substring(0, length) + ommision;
      }
    }
  }, option)

  new Vue( opt )

}

export default async function TAGSORT (opt, pagination_opt) {
  if( config.content == undefined ){
    console.error('TAGSORT.set_config(obj) で設定値を設定してください。')
    return false
  }
  const articles = await getArticles()
  const categories = await getCategories()
  const tags = getTags( articles )

  articles.map( article => {
    article.category = categories.filter( c => c.id == article.category )[0]
  })

  if( /^\?mode=list(&tag=[^&]+)?(&page=[\d]+)?$/.test( l.search ) || /^\?page=[\d]+/.test( l.search ) || l.search == "" ) render_list( articles, opt, pagination_opt )

  return {
    tags,
    articles
  }
}
